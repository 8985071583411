import React from "react";
import { motion } from "framer-motion";
import { useTheme } from "@emotion/react";
import { alpha } from "@mui/material";

export default function MainLogo({ isMed }) {
  const theme = useTheme();

  const icon = {
    hidden: {
      pathLength: 0,
      fill: "rgba(0, 0, 0, 0.01)",
    },
    visible: {
      pathLength: 1,
      fill: "rgba(0, 0, 0, 0.8)",
      transition: { duration: 3, delay: 0.3 },
      ease: "easeInOut",
    },
  };
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -60 595.28 841.89"
        variants={icon}
        initial="hidden"
        animate="visible"
        style={{
          width: "90%",
          height: "90%",
          overflow: "visible",
          stroke: "#fff",
          strokeWidth: "1.5",
          strokeLinejoin: "round",
          strokeLinecap: "round",
          filter: "drop-shadow(3px 20px 5px rgb(0 0 0 / 0.4))",
        }}
      >
        <g>
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st0}
            d="M323.72,366.31c-2.06-3.06-3.16-6.57-4.52-9.93c-3.54-8.72-6.26-17.76-7.92-27.02
		c-0.99-5.53-1.79-11.09-1.95-16.74c-0.18-6.29-0.09-12.57,0.44-18.84c0.29-3.43,1.67-6.72,1.15-10.49
		c-1.85,3.07-2.61,6.32-3.61,9.48c-0.3,0.94-0.11,2.13-1.75,2.37c-1.58-1.36-2.42-2.08-4-3.43c-0.21-3.41,1.5-6.17,2.77-9.03
		c4.43-9.93,10.11-19.08,17.09-27.47c6.94-8.34,15.02-15.33,23.72-21.69c1.19-0.87,2.26-1.9,3.81-2.66
		c-0.91-0.5-1.69-0.46-2.46-0.28c-1.92,0.45-3.77,1.19-5.56,2.03c-7.78,3.67-15.4,7.61-22.36,12.63
		c-7.07,5.1-13.98,10.43-21.05,15.54c-3.11,2.25-6.21,4.6-10.44,4.41c-3.86-0.18-7.43-2.61-8.71-6.35c-0.53-1.53-0.86-3.1-0.85-4.76
		c0.04-5.88-0.4-11.78,1.25-17.55c1.32-4.63,3.02-9.12,4.95-13.51c3.23-7.32,6.91-14.41,11.66-20.9c1.04-1.41,2.07-2.83,3.16-4.21
		c0.63-0.81,1.59-1.31,2.61-1.35c4.83-0.2,9.61,0.42,14.37,0.99c7.85,0.94,15.54,2.77,23.09,5.15c8.68,2.74,16.99,6.31,24.95,10.74
		c6.89,3.82,13.34,8.26,19.43,13.24c6.84,5.59,13.18,11.68,18.74,18.55c6.85,8.46,12.59,17.64,17.23,27.48
		c1.88,3.98,3.51,8.07,5.23,12.13c0.15,0.35,0.11,0.79,0.15,1.19c-0.88-0.04-1.77-0.04-2.65-0.12c-6.48-0.58-11.17,4.35-12.42,9.58
		c-0.9,3.77-0.93,3.77-4.93,3.95c-0.93,0.04-1.72-0.01-1.52,1.8c0.1,0.9,0.06,1.81-0.19,2.67c-0.62,2.15-0.51,3.2,1.23,3.32
		c3.6,0.24,3.59,0.29,3.05,3.96c-0.09,0.6-0.16,1.21-0.25,1.81c-0.61,4.36-1.21,8.73-1.84,13.09c-0.25,1.72,0.33,2.58,2.42,2.3
		c1.23-0.16,2.47-0.06,3.7,0.08c1.81,0.2,2.21-0.81,2.42-2.4c0.75-5.78,1.65-11.54,2.38-17.33c0.16-1.24,0.58-1.6,1.77-1.56
		c1.62,0.05,3.63,0.5,4.77-0.26c1.32-0.88,0.69-3.14,1.11-4.76c0.07-0.26,0.12-0.53,0.14-0.8c0.1-1.24-0.37-1.96-1.72-1.93
		c-1.15,0.03-2.31,0.02-3.47,0c-0.51-0.01-1.07,0.11-1.27-0.67c-0.57-2.21,1.62-5.26,3.63-4.51c3.27,1.23,4.13-0.55,4.93-2.93
		c0.06-0.18,0.24-0.31,0.37-0.47c1.61,3.87,2.23,8.01,3.25,12.04c0.1,0.39,0.08,0.82,0.49,1.05c-0.06,0.67-0.57,1.05-0.94,1.52
		c-5,6.54-5.51,16.85,2.94,22.11c-0.74,0.64-0.37,1.5-0.41,2.27c-0.22,3.53,0.41,7.09-0.38,10.6c-1.28,0.05-2.56,0.2-3.83,0.12
		c-1.53-0.1-1.82,0.81-1.9,2.39c-0.06,1.34-0.32,2.65-0.64,3.95c-0.43,1.77,0.21,2.61,1.81,2.6c1.22,0,2.43,0.02,3.65,0.03
		c0.26,1.23-0.62,2.37-0.3,3.61c-1.44,0.03-2.88,0.13-4.32,0.08c-0.95-0.03-1.78,0.64-1.91,1.59c-0.99,7.38-2.14,14.75-3.09,22.14
		c-0.23,1.76-1.09,3.57-0.12,5.38c0.13,0.86-0.24,1.58-0.59,2.32c-3.14,6.55-6.64,12.88-10.71,18.9c-1.82,2.69-3.69,5.33-5.7,7.88
		c-2.01,2.55-2.93,2.81-5.56,1.07c-6.08-4.02-12.03-8.21-17.72-12.77c-6.18-4.95-12.05-10.23-17.72-15.74
		c-0.47-0.46-1.12-0.81-1.19-1.59c1.49-0.03,3.01-0.26,4.47-0.05c2.27,0.32,2.69-0.85,2.92-2.72c0.7-5.65,1.53-11.29,2.32-16.93
		c0.99-7.05,2-14.1,2.92-21.16c0.1-0.73,0.87-1.78-0.42-2.3c-1.07-0.43-1.42,0.6-1.93,1.12c-7.57,7.7-15.14,15.41-22.63,23.2
		c-1.33,1.38-1.9,1.28-2.96-0.29c-5.18-7.65-10.49-15.21-15.77-22.8c-0.48-0.68-0.81-1.67-1.99-1.17c-0.11-2.14-1.57-3.67-2.52-5.42
		c-0.26-0.49-0.88-0.72-1.43-0.46c-0.79,0.38-0.42,1.02-0.23,1.58c0.71,2.05,1.44,4.09,2.17,6.13c0.22,0.61,0.24,1.39,1.13,1.5
		c-0.51,3.66-0.94,7.33-1.56,10.97C324.91,357.81,324.71,362.12,323.72,366.31z M312.23,233.09c0.01-3.41-1.02-6.54-2.79-8.5
		c-3.63-4.03-9.94-4.67-14.91-1.58c-6.62,4.12-9.63,10.18-9.21,17.82c0.39,7.1,5.59,10.74,11.24,10.29
		C305.17,250.43,312.57,240.07,312.23,233.09z M398.23,355.25c0.18-2.21-1.15-2.39-2.81-2.29c-1.39,0.09-2.78,0.01-4.17-0.01
		c-0.98-0.01-1.43,0.38-1.52,1.41c-0.34,3.56-1.1,7.08-1.27,10.65c-0.22,4.92-1.14,9.77-2.24,14.57c-0.42,1.83-0.03,2.75,1.68,2.7
		c1.43-0.04,2.86-0.12,4.28,0c1.63,0.14,2.28-0.33,2.47-2.07c0.52-4.71,1.21-9.41,2.01-14.09c0.62-3.68,3.53-5.92,7.21-5.85
		c2.97,0.06,4.74,1.87,4.69,4.89c-0.08,4.29-0.97,8.49-1.6,12.71c-0.66,4.4-0.7,4.4,3.7,4.4c4.17,0,4.29,0.02,4.89-4.05
		c0.77-5.23,1.81-10.44,1.55-15.78c-0.24-5.04-3.19-8.51-8.09-9.63C405.14,351.92,401.8,353.24,398.23,355.25z M367.51,328.41
		c2.92,0,5.84,0,8.76,0c5.05,0,5.05,0,5.65-5.1c0.17-1.45-0.89-2.65-2.35-2.65c-4.95-0.01-9.9-0.01-14.85,0
		c-0.87,0-1.57,0.06-1.36-1.29c0.92-5.82,1.74-11.65,2.56-17.48c0.63-4.42,1.2-8.86,1.81-13.28c0.21-1.52-0.44-2.11-2.2-1.87
		c-1.32,0.17-2.67,0.08-3.99-0.07c-2.04-0.23-2.65,0.7-2.85,2.65c-0.47,4.44-1.15,8.86-1.79,13.29c-1.13,7.84-2.28,15.68-3.45,23.51
		c-0.24,1.64,0.27,2.38,2.05,2.34C359.5,328.36,363.51,328.41,367.51,328.41z M330.61,307.18c3.54,0,3.61,0,3.98-3.46
		c0.06-0.55,0.16-1.46,0.28-2c0.32-1.46-0.18-2.28-1.72-2.3c-1.09-0.02-2.18-0.07-3.26,0.01c-1.26,0.09-1.48-0.52-1.36-1.61
		c0.34-3.18,1.89-4.23,5.04-3.38c0.69,0.18,1.51,0.93,2.08-0.28c0.83-1.79,1.78-3.52,2.55-5.33c0.51-1.19,0.15-2.02-1.25-2.43
		c-7.55-2.24-14.1,1.4-16.27,9.05c-0.35,1.24,0.04,2.88-0.93,3.67c-1.03,0.83-2.62,0.26-3.97,0.28c-1.07,0.02-1.61,0.26-1.57,1.74
		c0.04,1.24-0.07,2.49-0.41,3.68c-0.43,1.49-0.14,2.23,1.14,2.38c1.24,0.14,2.89-0.67,3.64,0.44c0.74,1.1-0.23,2.62-0.27,3.97
		c-0.03,0.79-0.38,1.56-0.2,2.37c0.57,2.48,0.62,5.05-0.32,7.41c-2,5-2.49,7.48,1,6.98c1.06-0.15,2.13-0.16,3.19,0.02
		c1.83,0.31,2.55-0.34,2.76-1.98c0.68-5.25,1.47-10.48,2.13-15.72C327.29,307.18,327.22,307.18,330.61,307.18z M381.2,353.2
		c-0.54,0-1.1,0.06-1.63-0.01c-1.74-0.23-2.47,0.3-2.61,2.24c-0.3,4.13-1.21,8.21-1.68,12.32c-0.47,4.03-1.06,8.05-1.76,12.04
		c-0.3,1.68,0.1,2.65,2.08,2.46c1.33-0.13,2.67-0.08,3.99,0.04c1.67,0.15,2.2-0.58,2.41-2.23c0.96-7.46,2.16-14.9,3.08-22.37
		C385.61,353.35,386.92,353.12,381.2,353.2z M390.66,328.4c1.02,0,2.04-0.02,3.06,0.01c0.84,0.03,1.17-0.31,1.28-1.17
		c1.17-8.67,2.39-17.33,3.6-25.99c0.22-1.55-0.54-2.15-2.53-1.82c-1.19,0.19-2.4,0.25-3.58,0.02c-1.54-0.3-2.15,0.05-2.31,1.19
		c-1.08,7.93-2.27,15.84-3.24,23.78C386.39,328.81,386.01,328.37,390.66,328.4z M383.05,340.55c-4.05,0-3.94,0-4.45,4.43
		c-0.11,0.94-0.32,1.87-0.46,2.81c-0.17,1.15,0.54,1.54,1.61,1.66c0.6,0.07,1.22,0.03,1.83,0.03c4.79,0,4.79,0,5.32-4.74
		C387.37,340.55,387.37,340.55,383.05,340.55z M396.19,286.69c-0.95,0-1.89,0-2.84,0c-0.58,0-1.07,0.09-1.16,0.82
		c-0.27,2.07-0.59,4.14-0.86,6.22c-0.15,1.18,0.44,1.79,1.59,1.86c0.67,0.04,1.35,0.02,2.03,0.02c4.31,0,4.31,0,5.07-4.16
		C400.89,286.69,400.89,286.69,396.19,286.69z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st0}
            d="M296.67,353.2c0.28-2.45-0.61-4.77-0.82-7.17c-0.5-5.7-0.82-11.41-0.82-17.13c0-0.78,0.28-1.65-0.47-2.28
		c3.49-1.77,6.18-4.53,8.92-7.23c0.79,0.43,0.52,1.19,0.53,1.82c0.23,12.89,2.56,25.39,6.42,37.69
		c3.46,11.04,7.74,21.71,13.03,31.98c5.77,11.23,12.71,21.72,20.63,31.54c4.29,5.32,8.85,10.41,13.44,15.47
		c1.53,1.68,3.5,2.97,5.32,4.37c1.99,1.53,2.07,2.28-0.03,3.6c-4.17,2.61-8.78,4.32-13.28,6.23c-7.03,2.99-14.31,5.36-21.75,7.08
		c-7.02,1.62-14.09,2.88-21.37,2.7c-7.38-0.18-14.77,0.18-22.14-0.15c-5.97-0.27-11.88-1.39-17.65-3.11
		c-1.29-0.38-2.62-0.63-3.92-0.99c-2.6-0.72-3.34-2.26-2.31-4.76c3.08-7.44,5.67-15.04,7.54-22.88c1.6-6.68,2.42-13.5,3.58-20.26
		c1.57-9.18,0.77-18.39,1.17-27.57c2.04,0.04,4.08-0.01,6.11,0.14c1.39,0.1,2.01-0.44,2.11-1.75c0.67,0.05,0.84,0.66,1.12,1.08
		c2.5,3.8,4.97,7.61,7.44,11.42c4.43,6.83,9.2,13.42,14.39,19.68c5.72,6.89,11.67,13.6,18.53,19.41c4.03,3.41,8.03,6.88,12.47,9.77
		c0.36,0.23,0.69,0.83,1.22,0.42c0.44-0.34,0.61-0.9,0.56-1.47c-0.06-0.71-0.47-1.25-0.87-1.81c-4.08-5.68-7.83-11.57-11.41-17.57
		c-5.9-9.85-11.27-19.98-15.64-30.62c-2.14-5.21-4.07-10.5-5.91-15.81c-0.04-1.69-0.08-3.26-0.12-4.94
		C305.01,361.49,302.91,355.66,296.67,353.2z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st0}
            d="M253.49,382.08c0.45,6.18-0.26,12.32-0.53,18.47c-0.17,3.79-0.5,7.55-0.89,11.32
		c-0.78,7.47-2.08,14.84-3.8,22.12c-2.83,11.96-7.05,23.42-12.41,34.49c-6.17,12.75-13.97,24.41-22.64,35.55
		c-0.1,0.1-0.27,0.27-0.38,0.38c-0.71,0.64-1.34,1.97-2.31,1.29c-0.85-0.59,0.09-1.78,0.47-2.64c4.82-10.97,8.75-22.26,12.1-33.75
		c2.32-7.96,4.36-16,5.81-24.16c1.11-6.22,1.77-12.53,2.85-18.73c1.11-6.36,0.94-12.76,1.77-19.11c0.29-2.21,0.1-4.48,0.1-6.72
		c0-8.57,0.12-17.13-0.06-25.7c-0.13-5.96-0.71-11.9-0.77-17.87c0-0.06-0.01-0.12-0.03-0.19c-0.11-1.22-0.23-2.62-0.34-3.84
		c0.01-1.15,0.01-2.3,0.02-3.45c1.05-0.22,1.03-1.1,1.15-1.89c0.2-1.4,0.36-2.8,0.61-4.19c0.45-2.55,0.38-2.69-2.22-3.05
		c-0.4-3.43-0.41-6.88-0.43-10.33c0-0.84,0.32-1.77-0.37-2.53c1.67-3.18,3.31-6.37,5-9.53c2.95-5.5,5.93-10.97,8.89-16.46
		c0.88-1.65,0.45-2.42-1.39-2.47c-1.49-0.04-2.99,0.06-4.48-0.03c-2.59-0.16-2.34,2.69-4.49,6.91c-0.34,0.67-0.89,1.19-1.45,1.69
		c-0.76,0.68-0.68,2.15-1.97,2.47c1.31-10.62,1.39-21.34,3.79-31.87c3.05-13.39,7.36-26.26,13.52-38.5
		c5.46-10.85,12.15-20.91,19.77-30.36c1.74-2.15,3.6-4.21,5.44-6.28c0.42-0.46,0.76-1.17,1.68-1.04c0.16,1.96-1.12,3.27-2.13,4.63
		c-6.65,8.94-11.38,18.88-15.37,29.21c-2.76,7.14-4.76,14.51-6.21,22c-1.13,5.82-1.79,11.73-2.29,17.66
		c-0.76,9.01-0.89,18.02-0.68,27.04c0.21,9.02,0.86,18.02,1.36,27.03c0.26,4.56,0.74,9.11,1.15,13.66c0.27,2.91-0.01,5.86,0.67,8.73
		c-8.49,0.43-14.98,5.45-17.27,13.36c-2.33,8.06,1.85,15.41,9.93,17.37C247.67,383.5,250.6,382.85,253.49,382.08z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st0}
            d="M204.1,299.58c0.13-5.16-0.05-10.34,0.47-15.46c1.22-11.94,4.16-23.49,8.76-34.6
		c4.18-10.12,9.51-19.63,15.6-28.7c3.28-4.88,6.68-9.67,10.29-14.32c0.53-0.68,0.94-1.46,1.62-2.53c-1.94,0.14-3.57,0.18-5.17,0.39
		c-7.24,0.95-14.47,1.94-21.7,2.94c-0.9,0.12-1.78,0.35-2.67,0.55c-0.61,0.14-1.18,0.13-1.79,0c-1.54-0.33-2.47-1.31-2.4-2.81
		c0.06-1.43,1.41-1.71,2.54-1.88c3.2-0.47,6.42-0.85,9.63-1.28c7.99-1.08,15.98-2.11,23.96-3.29c2.52-0.37,4.76-1.72,6.39-3.67
		c4.95-5.94,10.9-10.86,16.49-16.14c1.82-1.72,3.74-3.34,5.52-5.11c0.89-0.88,1.75-1.48,3.25-1c-6.18,7.89-12.31,15.72-18.71,23.88
		c3.56,0.17,6.57-0.66,9.62-0.92c4.1-0.35,8.17-1,12.25-1.49c3.42-0.41,6.84-0.79,10.26-1.17c0.54-0.06,1.09-0.03,1.63-0.06
		c1.15-0.06,2.04,0.28,2.25,1.54c0.2,1.21-0.21,2.11-1.41,2.61c-3.05,1.27-6.39,0.89-9.53,1.62c-4.33,1.01-8.76,1.55-13.2,1.88
		c-4.49,0.33-8.93,1.28-13.42,1.61c-1.78,0.13-2.77,1.16-3.52,2.41c-7.23,12.05-13.56,24.54-18.34,37.8
		c-3.2,8.86-5.9,17.86-7.62,27.12c-1.41,7.56-2.69,15.15-3.18,22.85c-0.14,2.23-0.27,4.46-0.38,6.7c-1.49,0.01-2.98,0.01-4.47,0.03
		c-1.96,0.04-2.37,0.6-1.85,2.54c0.65,2.42,1.31,4.83,1.99,7.24c1.67,5.92,3.33,11.85,5.06,17.76c0.28,0.95,0.22,1.78-0.36,2.48
		c-1.19,1.42-2.59,2.53-4.49,2.96c-1.21,0.27-2.88-0.33-3.55,0.67c-0.69,1.02-0.58,2.59-0.8,3.92c-0.08,0.47-0.01,0.97-0.13,1.41
		c-0.44,1.58,0.29,2.1,1.75,2.12c2.57,0.04,4.93-0.75,7.27-1.66c-0.08,6.15,1.02,12.21,1.29,18.33c0.02,0.46,0.18,0.78,0.68,0.85
		c-0.24,1.66-0.43,3.33-0.73,4.97c-1.04,5.69-1.29,11.49-2.54,17.15c-1.68-1.71-1.81-4.11-2.62-6.18
		c-1.62-4.12-2.83-8.41-4.21-12.62c4.95-0.21,4.95-0.21,5.62-5.19c0.02-0.13,0.01-0.27,0.03-0.4c0.23-1.5-0.24-2.35-1.94-2.23
		c-1.08,0.08-2.17-0.03-3.25,0.03c-1.27,0.07-1.5-0.59-1.16-1.87c0.34-1.31,0.49-2.67,0.6-4.02c0.32-3.93,1.06-3.95-3.62-3.88
		c-0.4,0.01-0.81-0.04-1.21-0.06c-1.27-5.8-2.44-11.62-3.22-17.51c-0.05-0.35-0.17-0.64-0.48-0.84c1.16-0.85,2.05-1.94,2.91-3.07
		C214.04,314.24,212.51,303.63,204.1,299.58z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st0}
            d="M345.28,377.44c2.27,4.33,5.34,8.12,8.25,12c5.14,6.85,10.98,13.1,17.27,18.9
		c5.17,4.77,10.77,9.05,16.65,12.93c0.75,0.5,1.37,1.1,2,1.72c0.78,0.76,0.84,2,0.12,2.83c-3.2,3.72-6.59,7.27-11.06,9.49
		c-2.04,1.02-3.98,0.3-5.62-1.15c-4.51-4.01-8.48-8.53-12.59-12.94c-3.19-3.42-6.03-7.11-9.04-10.67
		c-4.03-4.76-7.6-9.83-11.12-14.96c-2.83-4.12-5.62-8.28-8.05-12.67c-0.25-0.46-0.47-1.8-1.59-0.73c1.01-6.72,2.02-13.45,3.11-20.73
		c1.79,2.52,3.22,4.65,4.76,6.69C340.7,371.23,342.54,374.67,345.28,377.44z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M345.28,377.44c-2.74-2.77-4.58-6.21-6.9-9.29c-1.54-2.04-2.97-4.16-4.76-6.69c-1.09,7.28-2.1,14-3.11,20.73
		c-2.3,0.03-4.6,0-6.9,0.12c-1.49,0.08-1.92-0.55-1.73-1.91c0.53-3.68,1.03-7.37,1.53-11.06c0.14-1,0.2-2.02,0.3-3.03
		c0.99-4.19,1.19-8.5,1.91-12.74c0.62-3.64,1.05-7.31,1.56-10.97c0.31-1.11-0.13-2.41,0.87-3.33c1.18-0.5,1.52,0.49,1.99,1.17
		c5.27,7.59,10.58,15.15,15.77,22.8c1.06,1.57,1.63,1.67,2.96,0.29c7.49-7.78,15.06-15.49,22.63-23.2c0.51-0.52,0.86-1.55,1.93-1.12
		c1.28,0.52,0.51,1.58,0.42,2.3c-0.92,7.06-1.94,14.11-2.92,21.16c-0.79,5.64-1.63,11.27-2.32,16.93c-0.23,1.87-0.65,3.04-2.92,2.72
		c-1.46-0.21-2.98,0.02-4.47,0.05c-1.6,0.15-1.8-0.89-1.79-2.11c0.9-6.04,1.8-12.08,2.73-18.27c-0.45,0.12-0.7,0.12-0.81,0.24
		c-3.33,3.36-6.64,6.72-9.96,10.09c-1.39,1.35-2.78,2.7-4.16,4.06C346.6,376.9,346.12,377.48,345.28,377.44z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M303.48,319.38c-2.74,2.7-5.43,5.46-8.92,7.23c-3.33,1.64-6.9,2.91-10.54,2.81
		c-5.72-0.15-10.97-1.88-14.86-6.56c-5.83-7.02-5.21-19.08,0.84-26.71c5.23-6.58,12.01-9.86,20.4-9.82
		c4.13,0.02,7.7,1.76,11.19,3.73c1.52,1.64,3.03,3.29,4.55,4.93c0.8,2.72,2.11,5.28,2.31,8.19c0.34,5.06-1.06,9.65-3.63,13.94
		C304.36,317.86,303.92,318.62,303.48,319.38z M295.95,315.38c2.4-2.29,3.65-5.16,3.97-8.4c0.2-2.06,0.24-4.18-1.2-5.95
		c-1.33-3.61-4.1-5.59-7.73-6.1c-7.76-1.09-14.77,3.64-16.93,11.17c-1.91,6.67,1.52,13.1,7.94,14.37
		C287.55,321.56,292.31,319.86,295.95,315.38z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M57.79,367.44c0.36-11.54,4.01-17.91,10.63-22.61c7.19-5.11,14.9-6.2,23.05-2.52
		c2.77,1.25,4.83,3.43,6.62,5.87c0.64,0.87,0.82,1.35-0.38,1.98c-1.61,0.84-3.03,2.04-4.57,3.02c-0.97,0.61-1.7,0.68-2.72-0.4
		c-5.25-5.55-12.62-5.43-18.64-0.47c-3.43,2.82-5.32,6.57-5.49,10.89c-0.21,5.08,3.12,10.32,9.04,11.27
		c5.94,0.95,10.45-1.74,14.15-6c0.83-0.96,1.27-0.94,2.1-0.42c1.37,0.87,2.71,1.78,4.08,2.65c0.97,0.61,1.3,1.2,0.46,2.27
		c-4.44,5.64-10.07,9.14-17.28,10.05c-4.69,0.59-9.05-0.3-12.89-2.94C60.41,376.27,57.51,371,57.79,367.44z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M253.71,382.08c-2.9,0.77-5.82,1.42-8.82,0.69c-8.08-1.96-12.26-9.31-9.93-17.37
		c2.29-7.91,8.78-12.93,17.27-13.36c3.41,0.42,6.53,1.44,9.24,3.71c1.2,1.01,1.54-0.32,1.44-1.13c-0.19-1.54,0.6-1.7,1.78-1.67
		c1.49,0.04,2.99-0.01,4.48,0.01c2,0.04,2.41,0.67,2.12,2.69c-0.95,6.54-1.83,13.09-2.74,19.64c-0.11,0.29-0.22,0.57-0.32,0.86
		c-0.18,1.6-0.44,3.2-0.51,4.81c-0.04,1.13-0.57,1.34-1.53,1.3c-1.35-0.05-2.7-0.01-4.05-0.01c-2.85,0-2.85,0-2.59-2.87
		c0.25-0.27,0.4-0.65,0-0.78c-0.59-0.19-0.91,0.34-1.23,0.75C256.78,380.26,255.24,381.17,253.71,382.08z M252.67,359.46
		c-4.6-0.29-8.65,3.07-9.65,8.08c-0.56,2.82,0.3,5.23,2.59,7.02c2.02,1.57,5.55,1.69,7.83,0.37c3.59-1.21,6.25-5.05,6.13-8.85
		C259.46,362.21,256.47,359.35,252.67,359.46z"
          />
          <motion.path
            //   sherwin
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            // fill="theme.palette.primary.main"
            style={
              {
                // filter: "drop-shadow(2px 5px 1px rgb(0 0 0 / .4))",
                //   filter:
                //     "drop-shadow(2px 5px 1px alpha(theme.palette.primary.main, 0.5))",
              }
            }
            //   className={classes.st1}
            d="M102.66,365.02c0.5-4.16,0.83-8.35,1.54-12.48c0.63-3.65,0.58-7.41,1.82-10.97c0.29-0.84,0.61-1.05,1.38-1.04
		c1.76,0.03,3.53,0.04,5.3,0c1.44-0.03,1.75,0.77,1.58,1.97c-0.59,4.05-1.21,8.11-1.84,12.38c2.3-0.65,4.31-1.77,6.61-2.14
		c6.64-1.06,12.07,3.18,12.47,9.95c0.15,2.59-0.2,5.15-0.46,7.71c-0.1,0.29-0.2,0.57-0.3,0.86c-0.44,3.34-0.92,6.68-1.31,10.02
		c-0.09,0.81-0.43,1.03-1.15,1.02c-2.04-0.02-4.07,0-6.11-0.02c-0.88-0.01-1.28-0.38-1.27-1.39c0.03-3.07,0.63-6.06,1.14-9.07
		c0.37-2.16,0.67-4.33,0.93-6.5c0.43-3.58-3.44-5.43-6-4.99c-3.63,0.62-5.74,2.75-6.28,6.36c-0.49,3.27-0.84,6.55-1.26,9.83
		c-0.11,0.29-0.21,0.57-0.32,0.86c-0.42,4.92-0.42,4.92-5.37,4.92c-0.68,0-1.35-0.01-2.03,0c-1.51,0.02-1.81-0.82-1.59-2.12
		c0.8-4.75,1.58-9.51,2.37-14.26C102.75,365.65,102.83,365.35,102.66,365.02z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M528.91,372.02c2.66,3.85,6.78,4.28,10.32,1.33c1.15-0.96,1.79-2.94,3.41-2.81c1.37,0.11,2.67,1.2,3.99,1.87
		c2.25,1.13,2.31,1.31,0.7,3.36c-4.55,5.8-10.37,8.33-17.74,6.87c-4.66-0.93-8.66-6.04-9.3-10.62c-1.23-8.8,4.24-16.29,11.44-18.9
		c9.21-3.34,18.69,1.89,19.24,12.36c0.06,1.21-0.46,1.45-1.46,1.68c-6.29,1.41-12.56,2.88-18.84,4.34
		C530.09,371.64,529.52,371.84,528.91,372.02z M541.5,362.18c-2.26-2.14-4.41-2.65-6.88-1.93c-2.63,0.76-4.78,2.71-5.16,4.81
		C533.37,364.12,537.22,363.2,541.5,362.18z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M431.75,327.54c-8.46-5.26-7.94-15.57-2.94-22.11c0.36-0.48,0.88-0.86,0.94-1.52c2.27-2.21,4.8-3.98,7.89-4.9
		c9.49-2.8,18.12,3.04,18.32,12.98c0.02,1.21-0.59,1.1-1.31,1.26c-6.27,1.44-12.53,2.9-18.8,4.36c-0.58,0.14-1.39,0.04-1.6,0.69
		c-0.25,0.78,0.56,1.18,1.04,1.59c3.3,2.82,7.56,1.61,10.6-1.95c1.16-1.35,1.84-1.67,3.27-0.65c0.97,0.7,2.13,1.15,3.23,1.65
		c1.21,0.55,1.2,1.3,0.52,2.27c-3.54,5.09-8.48,7.92-14.59,8.15C436.19,329.45,433.67,329.12,431.75,327.54z M434,311.29
		c4.28-1.02,8.19-1.95,12.43-2.96c-1.66-1.84-3.46-2.5-5.47-2.21C437.93,306.55,435.6,308.1,434,311.29z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M222,338.52c-2.34,0.9-4.7,1.7-7.27,1.66c-1.46-0.03-2.19-0.54-1.75-2.12c0.12-0.45,0.05-0.95,0.13-1.41
		c0.22-1.33,0.11-2.9,0.8-3.92c0.67-1,2.34-0.4,3.55-0.67c1.9-0.42,3.3-1.53,4.49-2.96c0.58-0.7,0.64-1.53,0.36-2.48
		c-1.73-5.91-3.39-11.83-5.06-17.76c-0.68-2.41-1.34-4.82-1.99-7.24c-0.52-1.95-0.11-2.51,1.85-2.54c1.49-0.03,2.98-0.02,4.47-0.03
		c1.11-0.04,1.59,0.5,1.88,1.59c1.42,5.31,2.94,10.59,4.59,16.44c1.21-2.64,3.08-4.38,3.49-6.96c1.02-0.25,1.18-1.23,1.59-1.96
		c1.41-2.55,2.78-5.12,4.11-7.72c0.48-0.95,1.07-1.45,2.21-1.38c1.49,0.09,2.98-0.01,4.48,0.03c1.83,0.05,2.27,0.82,1.39,2.47
		c-2.95,5.49-5.94,10.97-8.89,16.46c-1.69,3.16-3.34,6.35-5,9.53c-0.38,0.63-0.79,1.24-1.13,1.89
		C228.33,333.2,225.93,336.56,222,338.52z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M204.1,299.58c8.41,4.05,9.95,14.66,4.07,22.39c-0.86,1.13-1.75,2.23-2.91,3.07
		c-3.18,2.71-6.94,4.33-11.01,4.39c-4.6,0.07-8.46-1.92-11.22-5.86c-4.25-6.05-1.97-14.31,2.15-18.94c3.78-4.25,8.39-6.22,14-6.12
		C200.88,298.55,202.45,299.21,204.1,299.58z M188.89,314.93c-0.04,3.82,2.54,6.47,6.29,6.46c4.51,0,8.52-4.05,8.55-8.63
		c0.03-3.77-2.44-6.39-6.07-6.45C193.04,306.23,188.94,310.26,188.89,314.93z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M280.91,380.55c-0.1,1.31-0.72,1.85-2.11,1.75c-2.03-0.15-4.07-0.1-6.11-0.14c-0.3-0.64-1.17-1.04-0.36-2.05
		c0.43-0.53,0.22-1.56,0.31-2.37c0.89-3.36,0.93-6.84,1.54-10.24c0.44-3.89,0.85-7.79,1.34-11.67c0.21-1.66,0.67-3.11,2.96-2.74
		c1.32,0.21,2.7,0.03,4.05,0.05c0.53,0.01,1.31-0.17,1.46,0.41c0.61,2.23,1.93,0.81,2.71,0.43c3.26-1.56,6.56-1.69,9.97-0.76
		c5.26,2.07,7.58,6.55,6.78,13.07c-0.32,2.62-0.65,5.24-0.97,7.85c-0.23,1.4-0.45,2.81-0.69,4.21c-0.68,3.94-0.68,3.93-4.77,3.94
		c-4.6,0.01-4.47,0.46-3.74-4.47c0.57-3.89,1.17-7.78,1.66-11.68c0.45-3.57-1.43-5.79-4.79-5.83c-3.72-0.04-6.48,1.94-7.23,5.56
		C281.88,370.69,281.37,375.62,280.91,380.55z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M425.99,290.82c-0.13,0.16-0.31,0.29-0.37,0.47c-0.8,2.39-1.66,4.16-4.93,2.93c-2-0.75-4.19,2.3-3.63,4.51
		c0.2,0.78,0.76,0.66,1.27,0.67c1.16,0.02,2.31,0.03,3.47,0c1.35-0.03,1.82,0.69,1.72,1.93c-0.02,0.27-0.07,0.54-0.14,0.8
		c-0.42,1.62,0.21,3.88-1.11,4.76c-1.15,0.76-3.15,0.31-4.77,0.26c-1.19-0.04-1.61,0.32-1.77,1.56c-0.73,5.78-1.63,11.55-2.38,17.33
		c-0.21,1.64-0.64,2.66-2.6,2.37c-1.26-0.18-2.58-0.11-3.87-0.02c-1.8,0.14-2.29-0.71-2.06-2.34c0.63-4.36,1.23-8.73,1.84-13.09
		c0.08-0.6,0.16-1.21,0.25-1.81c0.54-3.68,0.55-3.72-3.05-3.96c-1.27-0.09-1.67-0.67-1.55-1.83c0.17-1.62,0.38-3.23,0.49-4.86
		c0.07-1.1,0.76-1.07,1.55-1.1c4-0.17,4.02-0.18,4.93-3.95c1.26-5.23,5.94-10.16,12.42-9.58c0.88,0.08,1.76,0.08,2.65,0.12
		c0.11,0.07,0.22,0.16,0.34,0.21c2.68,1.09,2.67,1.09,1.48,3.84C426.06,290.28,426.05,290.56,425.99,290.82z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M448.32,352.36c3.91,0.24,7.44,1.2,9.4,5.1c0.59,1.17,0.38,1.91-0.88,2.33c-1.72,0.58-3.46,1.14-5.15,1.79
		c-0.73,0.28-1.1,0.12-1.49-0.52c-1.14-1.86-4.42-2.19-5.78-0.59c-0.58,0.68-0.46,1.3,0.2,1.94c1.55,1.52,3.56,2.19,5.45,3.08
		c1.59,0.75,3.17,1.53,4.46,2.72c3.24,3.01,2.94,7.89-0.71,11.24c-4.3,3.94-11.42,4.77-16.61,2.43c-2.56-1.15-3.83-3.31-4.93-5.64
		c-0.37-0.78-0.13-1.4,0.86-1.65c1.03-0.26,2.04-0.64,3.04-1.03c1.82-0.72,3.4-1.54,4.62,1.16c0.81,1.79,5.15,1.93,6.65,0.58
		c1.26-1.14,0.66-2.16-0.4-2.82c-1.52-0.93-3.19-1.64-4.81-2.41c-5.36-2.53-6.32-5.47-5.9-9.15c0.43-3.72,3.32-6.75,7.76-7.96
		C445.45,352.58,446.85,352.27,448.32,352.36z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M568.03,352.38c3.85,0.04,7.17,1.18,9.28,4.7c0.74,1.24,0.92,2.24-0.88,2.75c-1.29,0.37-2.52,0.95-3.77,1.46
		c-1.19,0.48-2.1,0.76-2.94-0.81c-0.77-1.43-4.07-1.41-5.25-0.24c-0.97,0.96-0.69,1.87,0.27,2.57c1.36,0.99,2.87,1.71,4.44,2.37
		c2.87,1.22,5.78,2.5,6.96,5.76c1.22,3.34-0.36,7.2-4.07,9.74c-4.42,3.03-12.57,3.59-17.09-0.12c-1.53-1.25-2.17-2.86-2.72-4.6
		c-0.22-0.7,0.03-1.16,0.77-1.39c1.21-0.38,2.43-0.76,3.59-1.25c1.62-0.69,2.79-0.96,3.72,1.2c0.85,1.98,5.22,2.2,6.94,0.67
		c1.06-0.95,0.99-1.6-0.2-2.51c-1.85-1.41-4.07-1.98-6.07-3.04c-3.85-2.04-5.49-5-4.74-9.24c0.64-3.67,3.19-5.75,6.43-7.04
		C564.35,352.66,566.15,352.23,568.03,352.38z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M184.95,352.37c3.87,0.05,7.15,1.24,9.27,4.73c0.77,1.27,0.83,2.26-0.93,2.74c-1.16,0.32-2.28,0.83-3.41,1.27
		c-1.26,0.48-2.39,1.29-3.36-0.71c-0.65-1.35-3.92-1.29-5.1-0.22c-0.98,0.89-0.84,1.82,0.13,2.57c1.34,1.03,2.85,1.75,4.42,2.41
		c2.95,1.25,5.87,2.64,7.07,5.94c1.17,3.22-0.45,7.06-4.06,9.55c-4.52,3.13-12.61,3.64-17.23-0.17c-1.38-1.14-2.16-2.67-2.66-4.36
		c-0.26-0.89-0.19-1.42,0.91-1.63c0.97-0.18,1.89-0.63,2.82-0.99c1.82-0.69,3.39-1.49,4.57,1.25c0.89,2.06,5.81,1.79,7.22-0.07
		c0.73-0.96,0.03-1.4-0.58-1.93c-1.75-1.51-4-2.09-6.01-3.1c-4-2-5.68-5-4.89-9.31c0.68-3.72,3.26-5.79,6.64-7.05
		C181.46,352.66,183.16,352.34,184.95,352.37z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M175.93,286.69c4.86,0,4.75-0.02,4,4.78c-1.3,8.39-2.49,16.79-3.62,25.2c-0.96,7.11-6.51,12.48-13.78,12.44
		c-1.87-0.01-3.74,0.09-5.6-0.36c-1.5-0.36-2-1.11-1.43-2.55c0.27-0.68,0.5-1.38,0.8-2.05c0.59-1.35,0.95-2.92,1.89-3.95
		c1.06-1.17,2.64,0,4,0.01c3.73,0.02,4.53-0.81,5.19-5.45c0.63-4.4,1.59-8.76,1.99-13.17c0.32-3.51,1.07-6.96,1.29-10.47
		c0.07-1.06,0.52-2.04,0.5-3.16c-0.02-0.67,0.53-1.34,1.51-1.28C173.75,286.75,174.84,286.69,175.93,286.69z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M208.94,343.38c0.4,0.02,0.81,0.07,1.21,0.06c4.85-0.07,3.88-0.05,3.59,4.31c-0.09,1.34-0.41,2.66-0.65,3.99
		c-0.18,1.02,0.12,1.54,1.25,1.47c1.08-0.06,2.17,0.05,3.25-0.03c1.7-0.12,2.17,0.73,1.94,2.23c-0.02,0.13-0.02,0.27-0.03,0.4
		c-0.67,4.98-0.67,4.98-5.62,5.19c-1.83-0.42-2.36,0.26-2.54,2.17c-0.47,5-1.28,9.98-1.95,14.96c-0.1,0.29-0.21,0.57-0.31,0.86
		c-0.27,3.27-0.27,3.27-3.61,3.27c-0.75,0-1.49,0-2.24,0c-2.77,0-3.39-0.69-2.71-3.37c0.99-3.87,0.93-7.91,1.95-11.77
		c0.29-0.25,0.4-0.51,0.09-0.83c-0.1-0.8-0.09-1.56,0.15-2.38c0.75-2.5,0.37-2.98-2.14-2.99c-2.55-0.01-2.83-0.24-2.54-2.75
		c0.11-0.98,0.31-1.95,0.44-2.94c0.19-1.42,0.92-2.52,2.69-2.07c0.13,0.03,0.27,0,0.4,0c3.07,0,3.07,0,3.33-3.19
		c0.25-0.27,0.34-0.56,0.14-0.89c0.09-0.65,0.18-1.31,0.27-1.96c0.3-0.24,0.41-0.51,0.1-0.83
		C205.19,343.56,205.19,343.56,208.94,343.38z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M466.45,361.1c-1.15-0.01-2.31-0.01-3.46-0.04c-0.97-0.03-1.57-0.49-1.45-1.52c0.15-1.32,0.39-2.63,0.57-3.95
		c0.28-2.03,1.48-2.58,3.38-2.29c1.54,0.24,2.75,0.2,2.77-2.1c0.02-1.74,0.67-3.48,0.66-5.3c-0.01-1.41,0.52-2.6,2.57-2.31
		c1.35,0.19,2.73,0.17,4.09,0.02c1.78-0.2,2.29,0.5,2.07,1.91c-0.34,2.13-0.56,4.28-0.93,6.4c-0.19,1.13,0.22,1.46,1.28,1.41
		c1.11-0.06,2.24,0.05,3.35-0.03c1.14-0.08,1.94,0.78,1.78,1.91c-0.23,1.67-0.57,3.32-0.76,4.99c-0.12,1.03-0.82,0.84-1.53,0.87
		c-1.34,0.06-2.69-0.06-4.03-0.06c-1.11-0.01-1.51,0.63-1.67,1.74c-0.71,5.2-1.58,10.39-2.14,15.61c-0.43,4.03-0.27,4.05-4.2,4.05
		c-0.07,0-0.14,0-0.2,0c-4.42,0-4.55-0.01-4.14-4.47c0.14-1.5,0.36-3,0.63-4.48c0.66-3.69,1.21-7.39,1.77-11.09
		C467.21,361.8,467.5,361.25,466.45,361.1z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M138.37,382.29c-0.82,0-1.64-0.07-2.44,0.01c-1.57,0.17-2.16-0.59-1.92-2.02c0.59-3.52,0.79-7.09,1.5-10.6
		c0.64-3.18,0.86-6.44,1.28-9.66c0.26-2.03,0.55-4.06,0.81-6.1c0.1-0.79,0.71-0.74,1.22-0.74c1.97-0.03,3.94-0.01,5.91-0.01
		c0.41,0,0.95-0.01,1.06,0.42c0.49,1.82,1.42,1,2.44,0.55c1.79-0.79,3.63-1.42,5.64-1.41c1.22,0.01,1.83,0.55,1.61,1.75
		c-0.39,2.06-0.7,4.12-0.87,6.21c-0.05,0.66-0.17,1.11-1.29,0.86c-4.84-1.11-8.47,1.54-9.19,6.51c-0.58,4-1.29,8-1.57,12.02
		c-0.14,1.99-0.85,2.44-2.57,2.23C139.46,382.24,138.91,382.29,138.37,382.29z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M492.78,377.41c-0.32,1.57-0.13,3.7-1.08,4.57c-1.11,1-3.24,0.23-4.93,0.31c-0.61,0.03-1.22,0.01-1.83-0.02
		c-1.02-0.04-1.52-0.58-1.36-1.58c0.51-3.13,1.4-6.25,1.49-9.39c0.1-3.57,1.52-6.9,1.43-10.49c-0.05-2.09,0.55-4.2,0.92-6.28
		c0.17-0.95,0.69-1.47,1.82-1.39c1.69,0.12,3.4,0.05,5.09,0.02c0.76-0.01,1.39,0.14,1.53,0.98c0.2,1.17,0.64,0.75,1.35,0.39
		c1.98-1.02,4.06-1.79,6.35-1.79c1.39,0,1.97,0.47,1.66,1.98c-0.4,1.97-0.6,3.99-0.91,5.99c-0.1,0.63-0.2,1.18-1.14,0.94
		c-4.61-1.19-8.24,0.97-9,5.44c-0.53,3.13-0.85,6.29-1.26,9.44C492.68,376.78,492.6,377.07,492.78,377.41z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M510.61,382.41c-0.2,0-0.41,0-0.61,0c-4.19,0-4.19,0-3.57-4.01c0.96-6.29,2.06-12.56,2.8-18.88
		c0.2-1.68,0.85-3.24,0.84-4.93c-0.01-0.94,0.43-1.32,1.39-1.29c1.7,0.05,3.4,0.04,5.1,0.01c1.3-0.02,1.89,0.49,1.69,1.85
		c-0.56,3.94-1.11,7.88-1.63,11.83c-0.58,4.34-1.18,8.69-1.68,13.04c-0.21,1.84-0.89,2.83-2.89,2.4
		C511.58,382.33,511.08,382.41,510.61,382.41z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M160.27,382.28c-4.09,0-4.54-0.07-3.75-4.87c0.52-3.21,0.55-6.48,1.34-9.66c0.6-2.42,0.64-4.97,0.95-7.46
		c0.26-2.1,0.57-4.2,0.82-6.3c0.09-0.79,0.65-0.78,1.18-0.79c1.97-0.02,3.93-0.01,5.9-0.01c1.35-0.01,1.57,0.85,1.43,1.91
		c-0.38,2.86-0.79,5.73-1.19,8.59c-0.78,5.53-1.68,11.06-2.29,16.61c-0.21,1.89-1.03,2.17-2.56,1.99
		C161.5,382.22,160.88,382.28,160.27,382.28z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M420.31,382.29c-0.97-1.81-0.11-3.62,0.12-5.38c0.95-7.39,2.1-14.75,3.09-22.14c0.16-1.21,0.54-1.65,1.75-1.6
		c1.49,0.06,2.98-0.04,4.47-0.07c1.71-0.08,2.23,0.73,2,2.37c-0.91,6.39-1.74,12.78-2.63,19.17c-0.3,2.12-0.8,4.22-0.97,6.35
		c-0.1,1.18-0.64,1.28-1.53,1.27C424.51,382.27,422.41,382.29,420.31,382.29z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M220.49,379.82c1.19-5.41,1.48-10.96,2.41-16.42c0.27-1.56,0.64-4.13,0.86-5.7c0.16-1.13,0.33-2.26,0.48-3.39
		c0.07-0.56,0.15-1.16,0.87-1.11c2.35,0.15,4.74-0.47,7.05,0.32c0.2,0.34,0.33,0.58,0.53,0.92c-0.62,4.08-1.3,8.15-1.89,12.23
		c-0.71,4.87-1.33,9.76-2.06,14.62c-0.28,1.89-1.95,0.53-2.83,1.08c-0.93,0.25-1.76,0.47-2.69,0.72
		C219.64,382.54,220.78,381.04,220.49,379.82z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st0}
            d="M351.28,372.32c3.32-3.37,6.63-6.74,9.96-10.09c0.12-0.12,0.36-0.11,0.81-0.24
		c-0.92,6.19-1.82,12.23-2.73,18.27c-2.11-1.6-3.93-3.47-5.54-5.59C353.1,373.78,352.12,373.1,351.28,372.32z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M430.05,349.5c-1.22-0.01-2.43-0.03-3.65-0.03c-1.46,0-2.12-0.69-1.9-2.16c0.26-1.73,0.55-3.46,0.79-5.19
		c0.15-1.08,0.58-1.68,1.83-1.6c1.27,0.08,2.55-0.07,3.83-0.12c2.84,0.29,2.84,0.29,2.43,3.22c-0.13,0.92-0.42,1.86-0.37,2.77
		C433.13,348.56,432.22,349.59,430.05,349.5z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M515.2,349.41c-0.47,0-0.95,0-1.42,0c-3.18,0-3.45-0.6-2.6-4.18c0.31-1.29,0.4-2.62,0.57-3.93
		c0.07-0.54,0.41-0.65,0.88-0.65c2.1-0.01,4.19-0.03,6.29,0c1.03,0.01,1.45,0.63,1.31,1.61c-0.28,1.99-0.62,3.98-0.88,5.97
		c-0.12,0.94-0.62,1.24-1.51,1.19C516.96,349.37,516.08,349.41,515.2,349.41z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M165.1,349.3c-4.64,0-4.63,0-3.83-4.5c0.09-0.52,0-1.07,0-1.61c-0.01-1.92,0.7-2.65,2.61-2.66
		c1.49-0.01,2.98,0.02,4.47-0.01c1.37-0.02,2.02,0.45,1.69,1.97c-0.32,1.44-0.47,2.91-0.64,4.38c-0.19,1.61-0.62,2.87-2.67,2.45
		C166.2,349.21,165.64,349.3,165.1,349.3z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st0}
            d="M221.2,382.27c1.66,0.03,3.31,0.05,4.97,0.08c-0.35,3.41,0.78,6.81-0.02,10.3
		C224.18,389.33,222.25,386.02,221.2,382.27z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st0}
            d="M274.17,367.5c-0.61,3.4-0.65,6.89-1.54,10.24c-0.73-2.76-0.41-5.56-0.19-8.34
		C272.51,368.57,272.92,367.52,274.17,367.5z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            className="st0"
            d="M466.45,360.96"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            className="st0"
            d="M205.05,349.1"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            className="st0"
            d="M204.9,349.99"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            className="st0"
            d="M102.5,365.91"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            className="st0"
            d="M102.66,365.02"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            className="st0"
            d="M130.74,371.25"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            className="st0"
            d="M131.04,370.39"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            className="st0"
            d="M109.43,376.52"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            className="st0"
            d="M109.12,377.38"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            className="st0"
            d="M492.93,376.52"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            d="M466.45,360.96"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            d="M205.05,349.1"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            d="M204.9,349.99"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            d="M102.5,365.91"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            d="M102.66,365.02"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            d="M130.74,371.25"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            d="M131.04,370.39"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            d="M109.43,376.52"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            d="M109.12,377.38"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            d="M492.93,376.52"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st0}
            d="M209.07,379.01c0.1-0.29,0.21-0.57,0.31-0.86"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st0}
            d="M268.23,376.15c0.11-0.29,0.22-0.57,0.32-0.86"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M398.23,355.25c3.57-2.01,6.91-3.32,10.78-2.44c4.9,1.12,7.84,4.58,8.09,9.63c0.26,5.34-0.78,10.55-1.55,15.78
		c-0.6,4.07-0.72,4.05-4.89,4.05c-4.4,0-4.36,0.01-3.7-4.4c0.63-4.22,1.52-8.42,1.6-12.71c0.06-3.02-1.72-4.83-4.69-4.89
		c-3.68-0.07-6.59,2.17-7.21,5.85c-0.79,4.68-1.49,9.38-2.01,14.09c-0.19,1.72-0.82,2.2-2.41,2.08c-1.42-0.11-2.86-0.07-4.28-0.01
		c-1.57,0.06-2.06-0.66-1.84-2.14c0.97-6.71,1.92-13.43,2.87-20.14c0.27-1.88,0.57-3.76,0.75-5.64c0.1-1.04,0.55-1.43,1.56-1.41
		c1.5,0.03,2.99,0.05,4.49,0C397.26,352.89,398.39,353.2,398.23,355.25z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M367.51,328.41c-4.01,0-8.01-0.06-12.02,0.03c-1.78,0.04-2.29-0.7-2.05-2.34c1.17-7.84,2.32-15.67,3.45-23.51
		c0.64-4.42,1.31-8.84,1.79-13.29c0.21-1.94,0.82-2.88,2.85-2.65c1.33,0.15,2.67,0.25,3.99,0.07c1.77-0.23,2.41,0.35,2.2,1.87
		c-0.61,4.43-1.19,8.86-1.81,13.28c-0.83,5.83-1.65,11.66-2.56,17.48c-0.21,1.35,0.49,1.29,1.36,1.29c4.95,0,9.9-0.01,14.86,0
		c1.46,0,2.51,1.2,2.34,2.65c-0.6,5.11-0.6,5.11-5.65,5.11C373.35,328.41,370.43,328.41,367.51,328.41z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M330.61,307.18c-3.38,0-3.32,0.01-3.76,3.5c-0.66,5.25-1.45,10.48-2.13,15.72c-0.2,1.56-0.86,2.22-2.5,2.02
		c-1.27-0.16-2.59-0.11-3.87-0.01c-1.77,0.14-2.33-0.65-2.09-2.3c0.62-4.36,1.22-8.73,1.82-13.1c0.07-0.47,0.2-0.94,0.21-1.41
		c0.05-1.35,1.01-2.87,0.27-3.97c-0.75-1.11-2.39-0.3-3.64-0.44c-1.05-0.12-1.43-0.63-1.31-1.62c0.2-1.61,0.48-3.22,0.58-4.85
		c0.07-1.12,0.61-1.31,1.57-1.33c1.34-0.03,2.93,0.55,3.97-0.28c0.97-0.78,0.58-2.43,0.93-3.67c2.18-7.66,8.72-11.29,16.27-9.05
		c1.4,0.42,1.76,1.24,1.25,2.43c-0.78,1.81-1.72,3.55-2.55,5.33c-0.56,1.21-1.38,0.47-2.08,0.28c-3.16-0.84-4.71,0.2-5.04,3.38
		c-0.11,1.08,0.1,1.7,1.36,1.61c1.08-0.08,2.17-0.02,3.26-0.01c1.54,0.02,2.04,0.83,1.73,2.27c-0.13,0.59-0.2,1.2-0.27,1.81
		C334.22,307.18,334.22,307.18,330.61,307.18z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M381.2,353.2c5.72-0.09,4.41,0.14,3.87,4.51c-0.92,7.47-2.12,14.9-3.08,22.37c-0.22,1.69-0.76,2.42-2.53,2.22
		c-1.34-0.15-2.72-0.09-4.08-0.01c-1.73,0.1-2.13-0.77-1.91-2.28c0.7-4.76,1.37-9.53,2.06-14.3c0.49-3.42,1.17-6.83,1.42-10.27
		c0.14-1.95,0.87-2.48,2.61-2.24C380.1,353.26,380.66,353.2,381.2,353.2z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M390.66,328.4c-4.65-0.03-4.27,0.41-3.73-4c0.97-7.94,2.16-15.85,3.24-23.78c0.12-0.9,0.53-1.31,1.46-1.29
		c1.77,0.04,3.54,0.03,5.3,0c1.34-0.02,1.84,0.61,1.66,1.91c-1.21,8.66-2.43,17.32-3.6,25.99c-0.12,0.86-0.44,1.2-1.28,1.17
		C392.7,328.37,391.68,328.4,390.66,328.4z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M383.05,340.55c4.31,0,4.31,0,3.85,4.19c-0.52,4.74-0.52,4.74-5.32,4.74c-0.61,0-1.23,0.04-1.83-0.03
		c-1.17-0.13-1.92-0.59-1.54-2c0.24-0.91,0.32-1.87,0.43-2.81C379.11,340.55,379.11,340.55,383.05,340.55z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M396.19,286.69c4.7,0,4.7,0,3.83,4.76c-0.76,4.17-0.76,4.17-5.07,4.16c-0.68,0-1.36,0.02-2.03-0.02
		c-1.15-0.07-1.75-0.69-1.59-1.86c0.27-2.08,0.58-4.14,0.86-6.22c0.1-0.73,0.58-0.82,1.16-0.82
		C394.29,286.69,395.24,286.69,396.19,286.69z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st1}
            d="M232.67,349.54c-1.8-0.5-3.64-0.19-5.47-0.22c-1.55-0.02-2.12-0.66-1.76-2.15c0.03-0.13,0.07-0.26,0.09-0.4
		c0.32-1.94,0.07-4.31,1.11-5.7c1.15-1.53,3.69-0.17,5.56-0.67c2.6,0.36,2.67,0.5,2.22,3.05c-0.25,1.39-0.41,2.79-0.61,4.19
		C233.7,348.44,233.73,349.32,232.67,349.54z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st0}
            d="M295.95,315.38c0.31-4.9,0.9-9.75,2.77-14.35c1.44,1.77,1.4,3.89,1.2,5.95
		C299.6,310.22,298.35,313.09,295.95,315.38z"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            //   className={classes.st0}
            d="M253.45,374.92c-2.28,1.33-5.81,1.2-7.83-0.37c-2.3-1.79-3.15-4.19-2.59-7.02c0.99-5.02,5.05-8.37,9.65-8.08
		C253.23,364.6,253.59,369.75,253.45,374.92z"
          />

          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            d="M434,311.29"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
            variants={icon}
            fill="none"
            d="M446.43,308.33"
          />
        </g>
      </motion.svg>
    </div>
  );
}
