import React from "react";
import Container from "../container/Container";
// import Section from "../section/Section";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function Loading(props) {
  return (
    // <Section>
      <Container>
    <Stack spacing={1}>
      {/* For variant="text", adjust the height via font-size */}
      <Skeleton variant="text" sx={{ fontSize: "10rem" }} />

      {/* For other variants, adjust the size with `width` and `height` */}
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="rectangular" width={"100%"} height={60} />
      <Skeleton variant="rounded" width={"50%"} height={60} />
    </Stack>
       </Container>
    // </Section>
  );
}
