export const CHANGE_MOUSEUP = "CHANGE_MOUSEUP";
export const CHANGE_MOUSEDOWN = "CHANGE_MOUSEDOWN";

export const SET_LOGIN = "SET_LOGIN";
export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILED = "FETCH_DATA_FAILED";

export const USER_SIGNIN_REQUEST = "USER_SIGNIN_REQUEST";
export const USER_SIGNIN_SUCCESS = "USER_SIGNIN_SUCCESS";
export const USER_SIGNIN_FAIL = "USER_SIGNIN_FAIL";

export const USER_SIGNUP_REQUEST = "USER_SIGNUP_REQUEST";
export const USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS";
export const USER_SIGNUP_FAIL = "USER_SIGNUP_FAIL";

export const CREATE_EVENT_REQUEST = "CREATE_EVENT_REQUEST";
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";
export const CREATE_EVENT_FAIL = "CREATE_EVENT_FAIL";
export const CREATE_EVENT_RESET = "CREATE_EVENT_RESET";

export const DETAILS_USER_REQUEST = "DETAILS_USER_REQUEST";
export const DETAILS_USER_SUCCESS = "DETAILS_USER_SUCCESS";
export const DETAILS_USER_FAIL = "DETAILS_USER_FAIL";

export const SEND_MESSAGE_REQUEST = "SEND_MESSAGE_REQUEST";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_FAIL = "SEND_MESSAGE_FAIL";

export const USER_LOGOUT = "USER_LOGOUT";

export const SET_BACKDROP = "SET_BACKDROP";

export const SET_MODAL = "SET_MODAL";

export const FETCH_MESSAGES_REQUEST = "FETCH_MESSAGES_REQUEST";
export const FETCH_MESSAGES_SUCCESS = "FETCH_MESSAGES_SUCCESS";
export const FETCH_MESSAGES_FAILED = "FETCH_MESSAGES_FAILED";

export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILED = "FETCH_USERS_FAILED";

export const FETCH_WOWS_REQUEST = "FETCH_WOWS_REQUEST";
export const FETCH_WOWS_SUCCESS = "FETCH_WOWS_SUCCESS";
export const FETCH_WOWS_FAILED = "FETCH_WOWS_FAILED";

export const WOW_POST_REQUEST = "WOW_POST_REQUEST";
export const WOW_POST_SUCCESS = "WOW_POST_SUCCESS";
export const WOW_POST_FAILED = "WOW_POST_FAILED";

export const WOW_DELETE_REQUEST = "WOW_DELETE_REQUEST";
export const WOW_DELETE_SUCCESS = "WOW_DELETE_SUCCESS";
export const WOW_DELETE_FAILED = "WOW_DELETE_FAILED";
export const WOW_DELETE_RESET = "WOW_DELETE_RESET";

export const FETCH_WOW_REQUEST = "FETCH_WOW_REQUEST";
export const FETCH_WOW_SUCCESS = "FETCH_WOW_SUCCESS";
export const FETCH_WOW_FAILED = "FETCH_WOW_FAILED";

export const WOW_REFRESH = "WOW_REFRESH";
export const WOW_COMMENT_SUCCESS = "WOW_COMMENT_SUCCESS";
export const WOW_COMMENT_FAILED = "WOW_COMMENT_FAILED";
export const WOW_COMMENT_RESET = "WOW_COMMENT_RESET";
